import React from 'react';
import zoomIn from '../../assets/zoomIn.png';
import zoomOut from '../../assets/zoomOut.png';
import './colorPicker.css';

///Inspiration for this module comes from the React Color Picker
///Was using it initially but couldn't get it to render the way I wanted
///Big Props to them https://github.com/casesandberg/react-color

export default function ColorPicker(props) {

  const setClassName = (color) => {
    if (color === props.selectedColor) {
      return "colorPickerSelectedElement";
    }  else {
      if (color === "#ffffff") {
        return "colorPickerElement colorPickerElementWhite";
      } else {
        return "colorPickerElement"
      }
    }
  }

  const renderColors = () => {
    let tmp = [];
    let idx = 0;
    for (let c of props.colors) {
      tmp.push(
        <div key={`color-${idx}`}onClick={() => props.setColor(c)} style={{backgroundColor: `${c}`}} className={setClassName(c)}>
          </div>
      )
      idx += 1;
    }
    return tmp;
  }

  return (
    <div className="pickerHighLevelContainer">
      <div className="colorPickerContainer">
        {renderColors()}
      </div>
      <div onClick={() => props.toggleZoom(!(props.zoomScale === 30))} className="zoomButtonContainer">
          <img src={props.zoomScale === 30 ? zoomOut : zoomIn} alt="zoom" className="zoomButton"/>
      </div>
    </div>
  )
}