////////////////////////////////////////////////////////////////////
// CANVAS FUNCTIONS
////////////////////////////////////////////////////////////////////

export async function fetchCanvas() {
    try {
        const res = await fetch("https://us-central1-coti-5794a.cloudfunctions.net/getCanvas");
        if (res.status === "304") {
            return "";
        }
        const vals = await res.json();
        return vals.canvasString;
    } catch(error) {
        console.log(error);
        return "";
    }
}

export async function setCanvas(canvasString, fingerprint, idx, val) {
    const data = {
        idx: idx,
        val: val,
        canvasString: canvasString,
        fingerprint: `${fingerprint}`
    };
    try {
        const res = await fetch("https://us-central1-coti-5794a.cloudfunctions.net/setCanvas", {
            method: "POST",
            body: JSON.stringify(data),
        });
        if (res.status !== 200) throw Error;
        return {"status": true, "message": "Upload was succesful"};

    } catch(error) {
        console.log("something went wrong when uploading the new canvas", error);
        return {"status": false, "message": "Failed to upload"};
    }
}

/*export function setTile(idx, val) {
    fetch("https://us-central1-coti-5794a.cloudfunctions.net/setTileValue", {
        method: "POST",
        body: JSON.stringify({
            "tileIdx" : idx,
            "val": val,
        })
    })
    .then(res => {
        if (res.status !== 200) throw Error;
    })
    .catch((error) => {
        console.log(error);
    })
};*/

export const checkCanContribute = async (fingerprint) => {
    const data = {userFingerprint : `${fingerprint}`};
    try {
        const response = await fetch("https://us-central1-coti-5794a.cloudfunctions.net/userContributeCheck", { method: "POST", body: JSON.stringify(data)});
        const value = response.json();
        return value;
    } catch(error) {
        console.log(error);
        return {"canContribute": false, "timeToWait": 0};
    }
}

/*export async function setCanContribute(fingerprint) {
    const data = {userFingerprint: `${fingerprint}`};
    try {
        const response = await fetch("https://us-central1-coti-5794a.cloudfunctions.net/setContributeCheck", {
            method: "POST",
            body: JSON.stringify(data),
        });
        if (response.status !== 200) throw Error;
        
        return {"status": true};
    } catch(error) {
        console.log(error);

        return {"status": false, "message": "Something went wrong, please try again later."}
    }
}*/

////////////////////////////////////////////////////////////////////
// GALLERY FUNCTIONS
////////////////////////////////////////////////////////////////////


export const fetchGallery = async () => {
    try {
        const res = await fetch("https://us-central1-coti-5794a.cloudfunctions.net/getGallery");
        if (!res.status === "200") throw Error;
        const data = res.json();
        return data;
    } catch(error) {
        console.log("Failed to retrieve the gallery");
        return;
    }
}