import React from 'react';
import { isMobile } from "react-device-detect";
import { fetchGallery } from '../../firebase/APIFunctions';
import GalleryElement from './galleryElement';
import { LoaderAnimation } from '../../helpers/loader';
import './gallery.css';

export default function Gallery() {

  const [canvases, setCanvases]  = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const renderCanvases = () => {
    const tmp = [];
    canvases.forEach((canvas) => {
      tmp.push(<GalleryElement url={canvas[0]} isOngoing={false} title={canvas[1]}/>)
    })
    const frontElement = <GalleryElement url={"https://firebasestorage.googleapis.com/v0/b/coti-5794a.appspot.com/o/ongoing.png?alt=media&token=dc5e1156-3843-4c02-9198-9de1f6234d96"} isOngoing={true} />
    return [frontElement].concat(tmp);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    fetchGallery()
    .then((result) => {
      setCanvases(result);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    })
  }, [])

  if (isLoading) {
    return (
      <LoaderAnimation />
    )
  }

  return (
    <div style={{marginTop: '10vh'}}>
      <div className={isMobile ? "galleryContainerMobile" : "galleryContainer"}>
        {renderCanvases()}
      </div>
    </div>
  );
}