/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { isMobile } from "react-device-detect";
import getBrowserFingerprint from 'get-browser-fingerprint';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import scrollTo from '../../helpers/scroller';
import useWindowDimensions from '../../helpers/dimensions';
import ColorPicker from '../colorPicker/colorPicker';
import { checkCanContribute, fetchCanvas, setCanvas } from '../../firebase/APIFunctions';
import { LoaderAnimation } from '../../helpers/loader';
import './current.css';


const colorMap = [
  "#000000",
  "#0000ff",
  "#0055ff",
  "#00aaff",
  "#00ffff",
  "#005500",
  "#00aa00",
  "#00ff00",
  "#ff00ff",
  "#ff55ff",
  "#ffaaff",
  "#ff0000",
  "#ff5500",
  "#ffaa00",
  "#ffff00",
  "#ffffff",
];

function setCharAt(str,index,chr) {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);

}

export default function Current() {
  const {width, height} = useWindowDimensions();
  const canvasRef = React.useRef(null);
  const fingerprint = getBrowserFingerprint();
  const [zoomScale, setZoomScale] = React.useState(2);
  const [isLoading, setIsLoading] = React.useState(true);
  const [lastUpload, setLastUpload] = React.useState(0);
  const [selectedColor, setSelectedColor] = React.useState(colorMap[0]);
  const [selectedColorIdx, setSelectedColorIdx] = React.useState(0);
  const [highlightDiv, setHighlightDiv] = React.useState([0, 0]);

  const populate = (tiles) => {
    if (!tiles) {
      //No canvas string was passed to the function
      //It could be due to an error to the cloud function
      //Or it could be that the canvas hasn't changed since the last pull
      //This function shouldn't consider this an error, just return and log that there was nothing to update
      console.log("Nothing to update");
      return;
    }
    setIsLoading(true);
    const alpha = 255;
    try {
      const ctx = canvasRef.current.getContext('2d');
      ctx.canvas.width = 250;
      ctx.canvas.height = 250;
      const uintc8 = new Uint8ClampedArray(250000);
      let uintIdx = 0;
      for (let i = 0; i < 62500; i += 1) {
        const tempInt = parseInt(tiles[i], 16);
        const mapped = colorMap[tempInt] //Mapped Color?
  
        uintc8[uintIdx + 3] = alpha;
        uintc8[uintIdx] = parseInt(mapped.substring(1, 3), 16);
        uintc8[uintIdx + 1] = parseInt(mapped.substring(3, 5), 16);
        uintc8[uintIdx + 2] = parseInt(mapped.substring(5, 7), 16);
        uintIdx += 4;
      }
      const imgData = new ImageData(uintc8, 250);
      ctx.putImageData(imgData, 0, 0)
      setIsLoading(false);
    } catch (error) {
      toastAlert("Something went wrong when fetching the canvas");
      setIsLoading(false);
      console.log(error);
    }
  }

  const toggleZoom = async (isZooming) => {
    if (isZooming) {
      setZoomScale(30);
    } else {
      isMobile ? setZoomScale(1) : setZoomScale(2);
      window.scrollTo(0, 0);
    }
  }

  const toastNotify = (text) => toast(text);
  const toastAlert = (text) => toast.error(text);
  const toastSuccess = (text) => toast.success(text);

  const moveHighlight = (event) => {
    if (zoomScale !== 30) return;
    const x = Math.floor((event.pageX) / zoomScale) * zoomScale;
    const y = Math.floor((event.pageY - 54) / zoomScale) * zoomScale + 54;

    setHighlightDiv([x, y]);
  }

  if (isMobile  && zoomScale === 2) {
    setZoomScale(1);
  }

  const tileClick = async (event) => {
    let rect = canvasRef.current.getBoundingClientRect();
    //User cannot place a tile if not zoomed in (prevents accidentally placing a tile while fully zoomed out)
    //Needs to zoom in by either toggling the zoom manually, or by click on the canvas
    //Could change this if needed, but prevents errors for first time visitors
    if (zoomScale !== 30) {  //Shifts viewport to center clicked tile in view
      toggleZoom(true)
        .then(() => {
          const oldZoom = isMobile ? 1 : 2
          const newRect = canvasRef.current.getBoundingClientRect();
          const halfWindowWidth = width / 2;
          const halfWindowHeight = height / 2;
          let newX = Math.floor(newRect.left + (((event.pageX - rect.left) / oldZoom) * 30) - halfWindowWidth);
          if (newX < 0) newX = 0;
          let newY = Math.floor(newRect.top + (((event.pageY - rect.top) / oldZoom) * 30) - halfWindowHeight);
          if (newY < 0) newY = 0;
          window.scrollTo({
            left: newX,
            top: newY,
            behavior: 'instant',
          })
        });
      return;
    }
    if (isLoading) {
      toastAlert("Another operation is already ongoing");
      return;
    }
    if (lastUpload + 300000 >  Date.now()) {
      const timeToWait = Math.ceil(((lastUpload + 300000) - Date.now()) / 60000); 
      toastAlert(`${timeToWait} minute${timeToWait === 1 ? '' : 's'} until next tile can be placed`);
      return;
    }
    setIsLoading(true);
    checkCanContribute(fingerprint)
      .then((res) => {
        if (res["canContribute"]) {
          const x = Math.floor((event.clientX - rect.left) / zoomScale);
          const y = Math.floor((event.clientY - rect.top) / zoomScale);
          const mapped = colorMap[selectedColorIdx];
          const hexVal = colorMap.indexOf(mapped).toString(16)
          return updateCanvasString(x, 250 * y, hexVal);
        } else {
          setIsLoading(false);
          toastNotify(`${res["timeToWait"]} minute${res["timeToWait"] === 1 ? '' : 's'} until next tile can be placed`)
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
    rect = canvasRef.current.getBoundingClientRect()
    scrollTo(event.clientX, event.clientY, rect.left, rect.top); //Shifts viewport to center clicked tile in view
  }

  const keyboardNav = (event) => {
    event.preventDefault();
    if(event.keyCode === 37)      window.scrollBy(-10, 0);
    else if(event.keyCode === 39) window.scrollBy(10, 0);
    else if(event.keyCode === 38) window.scrollBy(0, -10);
    else if(event.keyCode === 40) window.scrollBy(0, 10);
  }

  const setColor = (hex) => {
    setSelectedColor(hex);
    let idx = 0;
    for (let i in colorMap) {
      if (colorMap[i] === hex) {
        idx = i
      }
    }
    setSelectedColorIdx(idx);
  }

  const updateCanvasString = async (x, y, val) => {
    const oldUploadVal = lastUpload;
    fetchCanvas()
    .then(cvs => {
      if (!cvs) {
        toastAlert("Failed to retrieve most recent canvas");
        throw Error;
      }
      const tmpString = setCharAt(cvs, x + y, val);
      populate(tmpString);
      return setCanvas(tmpString, fingerprint, `${x + y}`, val);
    })
    .then((uploadResponse) => {
      setIsLoading(false);
      setLastUpload(Date.now());
      if (uploadResponse["status"]) {
        toastSuccess(uploadResponse["message"]);
      } else {
        toastAlert(uploadResponse["message"]);
      }
    })
    .catch(error => {
      setLastUpload(oldUploadVal);
      setIsLoading(false);
      console.log(error);
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    try {
      populate(await fetchCanvas());
    } catch (error) {
      console.log("Could not fetch initial canvas info");
    }
    const fetcher = setInterval(async() => {
      try {
        populate(await fetchCanvas());
      } catch (error) {
        console.log("error fetching canvas info", error);
      }
    }, 300000);
  
    return () => {
      clearInterval(fetcher);
    };
  }, [])

  return (
    <>
      {isLoading? <LoaderAnimation/> : null}
      <div id="canvasView" style={{paddingTop: zoomScale !== 30 ? '15%' : '54px', display: 'flex', justifyContent: zoomScale !== 30 ? "center" : "start", alignItems: zoomScale !== 30 ? "center" : "start", height: '500px', width: 'auto', flexDirection: "column"}}>      
        <div className="canvasContainer" style={{width: `${250 * zoomScale}px`, height: `${250 * zoomScale}px`}} onKeyDown={(event) => keyboardNav(event)} onClick={(event) => tileClick(event)}>
          <canvas id="canvas" ref={canvasRef} className="canvasEl" onMouseMoveCapture={(event) => moveHighlight(event)}/>
        </div>
        {zoomScale === 30 ? <div style={{left: highlightDiv[0], top: highlightDiv[1], boxShadow: selectedColor === "#ffffff" ? '0px 0px 10px #000000' : `0px 0px 10px ${selectedColor}`, height: `${zoomScale}px`, width: `${zoomScale}px`, position: 'absolute'}} onClick={(event) => tileClick(event)} /> : null}
      </div>
      <ColorPicker colors={colorMap} zoomScale={zoomScale} toggleZoom={toggleZoom} setColor={setColor} selectedColor={selectedColor}/>
      <ToastContainer position="bottom-center"/>
    </>
  );
}