import { Navbar, Nav, Container } from 'react-bootstrap';

const Navigation = () => {
  return (
    <>
      <Navbar collapseOnSelect fixed="top" expand="sm" bg="light">
        <Container>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Brand href='/'>La Toile</Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href='/'>Current Canvas</Nav.Link>
              <Nav.Link href='/about'>About</Nav.Link>
              <Nav.Link href='/gallery'>Gallery</Nav.Link>
            </Nav>  
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;