import { Link } from 'react-router-dom'
import './about.css';

export default function About() {
  return (
    <div style={{display: "flex", width: '70%', margin: '0 auto', flexDirection: "column", paddingTop: '80px', textAlign: 'left'}}>
      <h3>What is La Toile</h3>
      <br />
      <p className="text-component"> <i> La Toile </i> - conveniently French for both a painting and The Web - is a monthly collaborative digital art project open to everybody.</p>
      <p className="text-component">On the first of each month, a blank canvas is released. The previous month's canvas is added to the Gallery and minted as an NFT to be auctioned on OpenSea, and proceeds will be donated to charity.</p>
      <br />
      <br />
      <h3>How does it work</h3>
      <br />
      <p className="text-component">To contribute to this month's canvas, select "Current Canvas" from the toolbar or click <Link to="/" style={{ textDecoration: 'none' }}>here</Link>. You can zoom in on the canvas by clicking on the zoom button next to the color palette, or by clicking anywhere on the canvas. Once zoomed in, pick a color form the palette and place your tile.</p>
      <br />
      <br />
      <h3>Other ways to contribute</h3>
      <br />
      <p className="text-component"><i>La Toile</i> is very much a work in progress! If you have any suggestions or ideas that you'd like to share, you can find us on Twitter <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/LaToile_Art" style={{ textDecoration: 'none' }}>@LaToile_Art</a></p>
    </div>
  );
}