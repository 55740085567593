export default function scrollTo(x, y, rectX, rectY) {
  const windowHeight = Math.round(window.innerHeight / 2);
  const windowWidth = Math.round(window.innerWidth / 2);

  const destinationX = x - windowWidth - Math.round(rectX);
  const destinationY = y - windowHeight - Math.round(rectY);

  window.scrollTo({
    top: destinationY,
    left: destinationX,
    behavior: 'smooth',
  })
}