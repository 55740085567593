import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";

export default function GalleryElement(props) {
  if (!props.isOngoing) {
    return (
      <div className={isMobile ? "galleryElementContainerMobile" : "galleryElementContainer"}>
        <div className="galleryImageContainer">
          <img className="galleryElementImage" src={props.url} alt="Canvas"/>
        </div>
        <div className="galleryElementTitleContainer">
          <p className="galleryElementTitle">{props.title}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={isMobile ? "galleryElementContainerMobile" : "galleryElementContainer"}>
        <Link to="/">
          <div className="galleryImageContainer">
            <img className="galleryElementImage" src={props.url} alt="Ongoing"/>
          </div>
        </Link>
        <div className="galleryElementTitleContainer">
          <p className="galleryElementTitle">Current Canvas</p>
        </div>
      </div>
    )
  }
}