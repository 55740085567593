import './App.css';
import { Route, Switch } from 'react-router-dom';
import Navigation from './components/navigation/navigation';
import About from './components/about/about';
import Current from './components/current/current';
import Gallery from './components/gallery/gallery';
import { CookiesProvider } from 'react-cookie';


function App() {
  return (
    <CookiesProvider>
      <div className="App">
        <Navigation/>
        <Switch>
          <Route path='/about' component={About} />
          <Route path='/gallery' component={Gallery} />
          <Route path='/' component={Current} />
        </Switch>
      </div>
    </CookiesProvider>
  );
}

export default App;
